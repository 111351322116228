import {NumberFilterModel} from 'ag-grid-community';
import {FilterModel} from '../../../filter-model';
import {FetchStatusFilterType} from '../fetch-status-filter-type';
import {ISimpleFilterModelType} from 'ag-grid-community/dist/types/src/filter/provided/iSimpleFilter';

export function isFetchStatusFilter(
  filterMode: FilterModel<ISimpleFilterModelType | FetchStatusFilterType>,
): filterMode is NumberFilterModel {
  return (
    filterMode.type === 'eq_found' ||
    filterMode.type === 'eq_not_found' ||
    filterMode.type === 'eq_error' ||
    filterMode.type === 'eq_no_mapping' ||
    filterMode.type === 'fetch_status_blank' ||
    filterMode.type === 'fetch_status_notBlank'
  );
}
