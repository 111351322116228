<div class="flex flex-col h-full">
  <div class="p-4 pb-0 flex items-start justify-between">
    <div class="flex-auto flex flex-col h-full justify-between">
      <div class="flex gap-4">
        <em-select
          class="w-full max-w-sm"
          [subscriptSizing]="'dynamic'"
          (selectionChange)="groupId.set($event)"
          [label]="'SHARED_LABEL_PRODUCT_GROUP' | translate"
          [value]="groupId()"
          *ngIf="asPage()"
        >
          <em-option [value]="''">
            {{ 'PRODUCT_FILTER_ALL' | translate }}
          </em-option>
          <em-option
            *ngFor="let productGroup of productGroups$ | async"
            [value]="productGroup.uuid"
          >
            {{ productGroup.name | productGroupName }}
          </em-option>
        </em-select>

        <em-products-list-layout
          *ngIf="gridIsReady()"
          [gridApi]="gridApi"
          (layoutChanged)="layoutChanged()"
        ></em-products-list-layout>
      </div>
      <div class="flex">
        <em-icon-button
          [matMenuTriggerFor]="columnsMenu"
          color=""
          icon="view_column"
          matTooltip="{{ 'ACTIONS_TOGGLE_COLUMNS' | translate }}"
        >
        </em-icon-button>

        <em-icon-button
          icon="filter_alt_off"
          color=""
          (btnClick)="clearFilters()"
          class="clear-filter-btn"
          [matBadge]="filtersCount ? filtersCount : ''"
          matTooltip="{{ 'SHARED_LABEL_CLEAR_FILTERS' | translate }}"
        ></em-icon-button>

        <div class="border-r border-darkColor m-3"></div>

        <em-icon-button
          icon="ios_share"
          color=""
          [routerLink]="['/export-products/add']"
          matTooltip="{{ 'NAV_EXPORT_CSV' | translate }}"
        ></em-icon-button>

        <em-csv-download></em-csv-download>
      </div>
    </div>
    <div class="flex flex-col justify-between content-end h-full items-end">
      <em-fetch-products-info *ngIf="asPage()"></em-fetch-products-info>

      <div class="flex items-center mb-1 mt-auto">
        <em-icon
          size="sm"
          name="shopping_cart"
          class="text-primary-500 mr-1"
        ></em-icon>
        {{ 'LABEL_PRODUCTS' | translate }}:
        <span class="font-bold ml-1"
          >{{ filteredProductsCount$ | async }}/{{ allProductsCount$ | async }}
        </span>
      </div>
    </div>
  </div>

  <!-- [themeStyleContainer]="styleRefItem"  used to inject the style here rather in document head,
      style in  head caused conflict with Tailwind reset (preflight) when applied to filter inputs (material-theme)
    -->
  <div class="flex-auto" #styleRefItem>
    <ag-grid-angular
      class="w-full h-full border-t border-t-gray-200 mat-elevation-z2"
      [theme]="theme"
      [themeStyleContainer]="styleRefItem"
      [columnDefs]="columnsDefAndTypes.columnsDef"
      [defaultColDef]="defaultColDef"
      [localeText]="localeText"
      [animateRows]="true"
      [rowModelType]="'infinite'"
      [datasource]="gridDataSource()"
      [infiniteInitialRowCount]="5"
      [cacheOverflowSize]="5"
      [enableCellTextSelection]="true"
      [columnTypes]="columnsDefAndTypes.columnsTypes"
      [overlayNoRowsTemplate]="'NO_PRODUCTS_AVAILABLE' | translate"
      (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
  </div>
</div>

<mat-menu #columnsMenu="matMenu" [class]="'!max-w-full'">
  <em-columns-selector
    *ngIf="currentColumnsDefs"
    class="block pr-6 pl-3"
    [colDefList]="currentColumnsDefs"
    [gridApi]="gridApi"
    (click)="$event.stopPropagation()"
  ></em-columns-selector>
</mat-menu>
