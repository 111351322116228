import {CommonModule} from '@angular/common';
import {Component, Inject, inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
} from '@angular/material/dialog';
import {EmIconButtonComponent} from '@em/shared/ui';
import {EmProductsListComponent} from '../products-list/products-list.component';

@Component({
  selector: 'em-products-list-modal',
  templateUrl: './products-list-modal.component.html',
  styleUrls: ['./products-list-modal.component.scss'],
  imports: [
    CommonModule,
    MatDialogModule,
    EmIconButtonComponent,
    EmProductsListComponent,
  ],
})
export class EmProductsListModalComponent {
  private readonly _matDialog = inject(MatDialog);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {productIds: string[]; title: string},
  ) {}
}
