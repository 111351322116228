import {
  CommonModule,
  CurrencyPipe,
  DecimalPipe,
  PercentPipe,
} from '@angular/common';
import {
  Component,
  DestroyRef,
  OnInit,
  Signal,
  computed,
  effect,
  inject,
  input,
  model,
  output,
  signal,
  untracked,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatBadgeModule} from '@angular/material/badge';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterLink} from '@angular/router';
import {
  FetchProductsStore,
  ProductsListService,
} from '@em/data-feed/data-access-products';
import {
  EmCardModule,
  EmIconButtonComponent,
  EmIconModule,
  EmSelectModule,
} from '@em/shared/ui';
import {getSelectedLocale} from '@em/shared/util-date-time';
import {isNotNullOrUndefined} from '@em/shared/util-rxjs';
import {CountryService} from '@em/user-account/data-access';
import {TranslateModule} from '@ngx-translate/core';
import {AgGridModule} from 'ag-grid-angular';
import {
  AllCommunityModule,
  ColDef,
  ColGroupDef,
  GridApi,
  GridReadyEvent,
  IDatasource,
  ModuleRegistry,
  provideGlobalGridOptions,
  themeMaterial,
} from 'ag-grid-community';
import {distinctUntilChanged, skip, tap} from 'rxjs';
import {EmProductGroupNamePipe} from '../../pipes/product-group-name/product-group-name.pipe';
import {ManageGroupsService} from '../../services/manage-groups/manage-groups.service';
import {createProductsListColumnsDefAndTypes} from '../columns-def/columns-def';
import {EmColumnsSelectorComponent} from '../columns-selector/columns-selector.component';
import {EmCsvDownloadComponent} from '../csv-download/csv-download.component';
import {EmFetchProductsInfoComponent} from '../fetch-products-info/fetch-products-info.component';
import {getGridDataSrouce} from '../grid-data-source/grid-data-source';
import {AG_GRID_LOCALE_DE} from '../i18n/ag-grid-local-de';
import {EmProductsListLayoutComponent} from '../layout/products-list-layout/products-list-layout.component';

ModuleRegistry.registerModules([AllCommunityModule]);
// Mark all grids as using legacy themes
provideGlobalGridOptions({theme: 'legacy'});

@Component({
  selector: 'em-products-list',
  imports: [
    CommonModule,
    TranslateModule,
    RouterLink,
    AgGridModule,
    MatMenuModule,
    MatTooltipModule,
    MatBadgeModule,
    EmColumnsSelectorComponent,
    EmSelectModule,
    EmIconModule,
    EmIconButtonComponent,
    EmCsvDownloadComponent,
    EmFetchProductsInfoComponent,
    EmProductGroupNamePipe,
    EmProductsListLayoutComponent,
  ],
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
  providers: [ProductsListService, CurrencyPipe, DecimalPipe, PercentPipe],
})
export class EmProductsListComponent implements OnInit {
  // Injectors
  private readonly _productsListService = inject(ProductsListService);
  private readonly _manageGroupsService = inject(ManageGroupsService);
  private readonly _fetchProductsStore = inject(FetchProductsStore);
  private readonly _countryService = inject(CountryService);
  private readonly _destroyRef = inject(DestroyRef);

  // Inputs
  groupId = model<string | undefined>();
  productIds = model<string[] | undefined>();
  asPage = input(true);

  // Outputs
  filterClear = output<void>();

  protected gridApi!: GridApi;
  protected localeText:
    | {
        [key: string]: string;
      }
    | undefined;
  protected theme = themeMaterial.withParams({
    cellHorizontalPadding: '15px',
  });

  readonly columnsDefAndTypes = createProductsListColumnsDefAndTypes();
  readonly productGroups$ = this._manageGroupsService.getGroups();
  readonly allProductsCount$ = this._productsListService.allProductsCount$;
  readonly filteredProductsCount$ =
    this._productsListService.filteredProductsCount$;

  readonly defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    width: 150,
  };

  gridIsReady = signal(false);
  gridDataSource: Signal<IDatasource> = computed(() =>
    getGridDataSrouce(this._productsListService, () => ({
      group: this.groupId(),
      productIds: this.productIds(),
    })),
  );
  currentColumnsDefs: Array<ColDef | ColGroupDef> | undefined;

  get filtersCount() {
    return Object.keys(this.gridApi?.getFilterModel() || {}).length;
  }

  constructor() {
    effect(() => {
      const isReady = untracked(this.gridIsReady);
      const groupId = this.groupId();
      const productIds = this.productIds();

      // Update the datasource when group or products change and grid is already initialized
      // if group or products changed before grid is initializded they will be included in the initial datasource
      if (isReady) {
        this.gridApi.setGridOption(
          'datasource',
          getGridDataSrouce(this._productsListService, () => ({
            group: groupId,
            productIds,
          })),
        );
      }
    });
  }

  ngOnInit(): void {
    // Set grid local
    const locale = getSelectedLocale();
    if (locale?.code?.includes('de')) {
      this.localeText = AG_GRID_LOCALE_DE;
    }

    // show/hide empty row in the grid
    this._productsListService.isEmpty$.subscribe((isEmpty) => {
      if (this.gridApi) {
        if (isEmpty) {
          this.gridApi.showNoRowsOverlay();
        } else {
          this.gridApi.hideOverlay();
        }
      }
    });

    // Refresh the grid when new fetch finish
    this._fetchProductsStore.uploadFinished$
      .pipe(
        isNotNullOrUndefined(),
        distinctUntilChanged(),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe(() => {
        this._productsListService.reloadTotalCount();
        this.refreshGrid();
      });

    // Refresh Grid when a country change
    this._countryService
      .observable()
      .pipe(
        skip(1),
        tap(() => this.refreshGrid()),
      )
      .subscribe();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;

    this.gridIsReady.set(true);

    // keep track of the latest columns def after restoring the current state
    this.currentColumnsDefs = this.gridApi.getColumnDefs();
  }

  layoutChanged() {
    // keep track of the latest columns def after restoring the current state
    this.currentColumnsDefs = this.gridApi.getColumnDefs();
  }

  refreshGrid() {
    if (this.gridDataSource() && this.gridApi) {
      this.gridApi.setGridOption('datasource', this.gridDataSource());
    }
  }

  clearFilters() {
    this.gridApi.setFilterModel(null);
    this.filterClear.emit();
  }
}
