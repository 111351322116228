import {Route} from '@angular/router';
import {EmProductGroupsPageComponent} from './product-groups/product-filter/product-groups-page/product-groups-page.component';
import {EmProductsListPageComponent} from './products-list-page/products-list-page/products-list-page.component';

export const dataFeedFeatureProductsRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full',
  },
  {path: 'list', component: EmProductsListPageComponent},

  {
    path: 'groups/:type',
    component: EmProductGroupsPageComponent,
  },
  {
    path: 'groups',
    redirectTo: 'groups/custom',
  },
  {
    path: 'filter',
    redirectTo: 'groups/custom',
  },
];
