import {
  CommonModule,
  CurrencyPipe,
  DecimalPipe,
  PercentPipe,
} from '@angular/common';
import {Component, inject} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductsListService} from '@em/data-feed/data-access-products';
import {map} from 'rxjs';
import {EmProductsListComponent} from '../products-list/products-list.component';
import {EmCardModule} from '@em/shared/ui';

@Component({
  selector: 'em-products-list-page',
  imports: [CommonModule, EmProductsListComponent, EmCardModule],
  templateUrl: './products-list-page.component.html',
  styleUrls: ['./products-list-page.component.scss'],
  providers: [ProductsListService, CurrencyPipe, DecimalPipe, PercentPipe],
})
export class EmProductsListPageComponent {
  // Injectors
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _router = inject(Router);

  private readonly _queryParamsMap = this._activatedRoute.queryParamMap;
  protected groupId = toSignal(
    this._queryParamsMap.pipe(
      map((queryParamMap) => queryParamMap.get('group') || undefined),
    ),
  );

  groupChanged(groupId: string | undefined) {
    if (groupId) {
      this._router.navigate(['./'], {
        relativeTo: this._activatedRoute,
        queryParams: {group: groupId},
      });
    } else {
      this._router.navigate(['./'], {
        relativeTo: this._activatedRoute,
      });
    }
  }

  clearFilters() {
    this._router.navigate(['./'], {
      relativeTo: this._activatedRoute,
      queryParams: this.groupId() ? {group: this.groupId()} : {},
    });
  }
}
