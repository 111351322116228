import {
  ProductsListDateFilterOperators,
  ProductsListNumberFilterOperators,
  ProductsListTextFilterOperators,
} from '@em/shared/api-interface';
import {ISimpleFilterModelType} from 'ag-grid-community/dist/types/src/filter/provided/iSimpleFilter';

export const GRID_TO_BACKEND_TEXT_OPERATOR: {
  [key in ISimpleFilterModelType]?: ProductsListTextFilterOperators;
} = {
  contains: 'contains',
  notContains: 'not_contains',
  equals: 'equals',
  notEqual: 'not_equals',
  startsWith: 'starts_with',
  blank: 'is_empty',
  notBlank: 'not_empty',
};

export const BACKEND_TO_GRID_TEXT_OPERATOR: {
  [key in ProductsListTextFilterOperators]?: ISimpleFilterModelType;
} = {
  contains: 'contains',
  not_contains: 'notContains',
  equals: 'equals',
  not_equals: 'notEqual',
  starts_with: 'startsWith',
  is_empty: 'blank',
  not_empty: 'notBlank',
};

export const GRID_TO_BACKEND_NUMBER_OPERATOR: {
  [key in ISimpleFilterModelType]?: ProductsListNumberFilterOperators;
} = {
  equals: 'eq',
  greaterThan: 'gt',
  greaterThanOrEqual: 'ge',
  lessThan: 'lt',
  lessThanOrEqual: 'le',
  blank: 'is_empty',
  notBlank: 'not_empty',
};

export const BACKEND_TO_GRID_NUMBER_OPERATOR: {
  [key in ProductsListNumberFilterOperators]?: ISimpleFilterModelType;
} = {
  eq: 'equals',
  gt: 'greaterThan',
  ge: 'greaterThanOrEqual',
  lt: 'lessThan',
  le: 'lessThanOrEqual',
  is_empty: 'blank',
  not_empty: 'notBlank',
};

export const GRID_TO_BACKEND_DATE_OPERATOR: {
  [key in ISimpleFilterModelType]?: ProductsListDateFilterOperators;
} = {
  equals: 'eq',
  greaterThan: 'gt',
  greaterThanOrEqual: 'ge',
  lessThan: 'lt',
  lessThanOrEqual: 'le',
  blank: 'is_empty',
  notBlank: 'not_empty',
};

export const BACKEND_TO_GRID_DATE_OPERATOR: {
  [key in ProductsListDateFilterOperators]: ISimpleFilterModelType;
} = {
  eq: 'equals',
  gt: 'greaterThan',
  ge: 'greaterThanOrEqual',
  lt: 'lessThan',
  le: 'lessThanOrEqual',
  is_empty: 'blank',
  not_empty: 'notBlank',
};
