{
  "$schema": "http://json-schema.org/draft-06/schema#",
  "definitions": {
    "selected_filters": {
      "items": {
        "anyOf": [
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "gtin"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "exclude_products": {
                    "type": "boolean"
                  }
                }
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "key",
              "options"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "best_price"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "tolerance_enabled": {
                    "type": "boolean"
                  },
                  "tolerance_percent": {
                    "type": "number"
                  },
                  "shipping_costs_excluded": {
                    "type": "boolean"
                  },
                  "exclude_products": {
                    "type": "boolean"
                  }
                },
                "anyOf": [
                  {
                    "not": {
                      "properties": {
                        "tolerance_enabled": {
                          "enum": [
                            true
                          ]
                        }
                      },
                      "required": [
                        "tolerance_enabled"
                      ]
                    }
                  },
                  {
                    "required": [
                      "tolerance_enabled",
                      "tolerance_percent"
                    ]
                  }
                ]
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "options",
              "key"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "with_conversions",
                  "with_impressions",
                  "with_clicks",
                  "with_costs",
                  "price",
                  "current_price",
                  "roas",
                  "cogs"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "value": {
                    "type": "number"
                  },
                  "operator": {
                    "type": "string",
                    "enum": [
                      "gt",
                      "ge",
                      "eq",
                      "le",
                      "lt"
                    ]
                  }
                }
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "key",
              "options"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "suggested_price",
                  "predicted_impressions_change_fraction",
                  "predicted_conversions_change_fraction",
                  "predicted_clicks_change_fraction",
                  "benchmark_price"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "value": {
                    "type": "number"
                  },
                  "operator": {
                    "type": "string",
                    "enum": [
                      "gt",
                      "ge",
                      "eq",
                      "le",
                      "lt",
                      "is_empty",
                      "not_empty"
                    ]
                  }
                }
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "key",
              "options"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "with_competitors"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "value": {
                    "type": "number"
                  },
                  "operator": {
                    "type": "string",
                    "enum": [
                      "gt",
                      "ge",
                      "eq",
                      "le",
                      "lt"
                    ]
                  },
                  "source": {
                    "type": "string",
                    "enum": ["google-shopping", "idealo"]
                  }
                }
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "key",
              "options"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "fetch_status"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "operator": {
                    "type": "string",
                    "enum": ["eq", "is_empty", "not_empty"]
                  },
                  "value": {
                    "type": "string",
                    "enum": ["found", "not_found", "no_mapping", "error"]
                  }
                },
                "anyOf": [
                  {
                    "not": {
                      "properties": {
                        "operator": {
                          "enum": ["is_empty", "not_empty"]
                        }
                      },
                      "required": [
                        "operator",
                        "value"
                      ]
                    }
                  },
                  {
                    "required": [
                      "operator"
                    ]
                  }
                ]
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "key",
              "options"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "price_changes"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "value": {
                    "type": "integer"
                  },
                  "operator": {
                    "type": "string",
                    "enum": [
                      "gt",
                      "ge",
                      "eq",
                      "le",
                      "lt"
                    ]
                  },
                  "last_data_points": {
                    "type": "integer",
                    "minimum": 1,
                    "maximum": 7
                  },
                  "include_with_insufficient_data": {
                    "type": "boolean"
                  },
                  "column": {
                    "type": "string",
                    "enum": ["min_price", "min_price_full", "avg_price", "avg_price_full"]
                  },
                  "tolerance_enabled": {
                    "type": "boolean"
                  },
                  "tolerance_percent": {
                    "type": "number"
                  },
                  "source": {
                    "type": "string",
                    "enum": ["google-shopping", "idealo"]
                  }
                },
                "anyOf": [
                  {
                    "not": {
                      "properties": {
                        "tolerance_enabled": {
                          "enum": [
                            true
                          ]
                        }
                      },
                      "required": [
                        "value",
                        "operator",
                        "last_data_points",
                        "tolerance_enabled"
                      ]
                    }
                  },
                  {
                    "required": [
                      "value",
                      "operator",
                      "last_data_points",
                      "tolerance_enabled",
                      "tolerance_percent"
                    ]
                  }
                ]
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "key",
              "options"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "product_performance"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "roas": {
                    "type": "number"
                  },
                  "costs": {
                    "type": "number"
                  }
                },
                "required": [
                  "roas",
                  "costs"
                ]
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "options",
              "key"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "mode_price_v2"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "range_mode": {
                    "type": "string",
                    "enum": [
                      "below",
                      "within",
                      "above"
                    ]
                  },
                  "shipping_costs_excluded": {
                    "type": "boolean"
                  },
                  "exclude_products": {
                    "type": "boolean"
                  }
                },
                "required": [
                  "range_mode"
                ]
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "options",
              "key"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "outliers"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "shipping_costs_excluded": {
                    "type": "boolean"
                  },
                  "exclude_products": {
                    "type": "boolean"
                  }
                }
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "options",
              "key"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "uploaded_since",
                  "source_created_at"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "days": {
                    "type": "integer"
                  },
                  "operator": {
                    "type": "string",
                    "enum": [
                      "gt",
                      "ge",
                      "eq",
                      "le",
                      "lt"
                    ]
                  }
                },
                "required": [
                  "days",
                  "operator"
                ]
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "options",
              "key"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "profit_margin"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "value": {
                    "type": "number"
                  },
                  "operator": {
                    "type": "string",
                    "enum": [
                      "gt",
                      "ge",
                      "eq",
                      "le",
                      "lt"
                    ]
                  }
                },
                "required": [
                  "value",
                  "operator"
                ]
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "options",
              "key"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "category"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "categories": {
                    "type": "array",
                    "minItems": 1,
                    "items": {
                      "type": "string"
                    }
                  },
                  "exclude_products": {
                    "type": "boolean"
                  }
                },
                "required": [
                  "categories"
                ]
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "options",
              "key"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "offer_ids"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "offer_ids": {
                    "type": "array",
                    "items": {
                      "type": "string"
                    }
                  }
                },
                "required": [
                  "offer_ids"
                ]
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "options",
              "key"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "ids"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "ids": {
                    "type": "array",
                    "items": {
                      "type": "string"
                    }
                  }
                },
                "required": [
                  "ids"
                ]
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "options",
              "key"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "brand",
                  "title"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "operand": {
                    "type": "string"
                  },
                  "operator": {
                    "type": "string",
                    "enum": [
                      "equals",
                      "not_equals",
                      "contains",
                      "not_contains",
                      "starts_with",
                      "not_starts_with"
                    ]
                  }
                },
                "required": [
                  "operand",
                  "operator"
                ]
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "options",
              "key"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "availability"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "operand": {
                    "type": "string",
                    "enum": [
                      "in stock",
                      "out of stock",
                      "preorder"
                    ]
                  },
                  "operator": {
                    "type": "string",
                    "enum": [
                      "equals",
                      "not_equals"
                    ]
                  }
                },
                "required": [
                  "operand",
                  "operator"
                ]
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "options",
              "key"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "custom_labels"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "operand": {
                    "type": "array",
                    "items": {
                      "type": "string"
                    }
                  },
                  "operator": {
                    "type": "string",
                    "enum": [
                      "equals",
                      "not_equals",
                      "contains",
                      "not_contains"
                    ]
                  }
                },
                "required": [
                  "operand",
                  "operator"
                ]
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "options",
              "key"
            ],
            "type": "object",
            "additionalProperties": false
          },
          {
            "properties": {
              "uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "parent_uuid": {
                "$ref": "generic.json#/definitions/uuid"
              },
              "key": {
                "type": "string",
                "enum": [
                  "all"
                ]
              },
              "options": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                },
                "required": [
                ]
              }
            },
            "required": [
              "uuid",
              "parent_uuid",
              "options",
              "key"
            ],
            "type": "object",
            "additionalProperties": false
          }
        ]
      },
      "type": "array"
    }
  }
}
