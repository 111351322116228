<div class="flex border-b text-center bg-primary-100">
  <div class="p-4" *ngIf="data.title">
    {{ data.title }}
  </div>
  <em-icon-button
    mat-dialog-close
    color=""
    icon="close"
    class="ml-auto"
  ></em-icon-button>
</div>

<em-products-list
  [productIds]="data.productIds"
  [asPage]="false"
></em-products-list>
